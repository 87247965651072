exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-about-de-js": () => import("./../../../src/pages/about.de.js" /* webpackChunkName: "component---src-pages-about-de-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-blog-de-js": () => import("./../../../src/pages/blog.de.js" /* webpackChunkName: "component---src-pages-blog-de-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-contact-de-js": () => import("./../../../src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-cookies-policy-de-js": () => import("./../../../src/pages/cookies-policy.de.js" /* webpackChunkName: "component---src-pages-cookies-policy-de-js" */),
  "component---src-pages-cookies-policy-en-js": () => import("./../../../src/pages/cookies-policy.en.js" /* webpackChunkName: "component---src-pages-cookies-policy-en-js" */),
  "component---src-pages-devops-services-de-js": () => import("./../../../src/pages/devops-services.de.js" /* webpackChunkName: "component---src-pages-devops-services-de-js" */),
  "component---src-pages-devops-services-en-js": () => import("./../../../src/pages/devops-services.en.js" /* webpackChunkName: "component---src-pages-devops-services-en-js" */),
  "component---src-pages-digital-transformation-consulting-de-js": () => import("./../../../src/pages/digital-transformation-consulting.de.js" /* webpackChunkName: "component---src-pages-digital-transformation-consulting-de-js" */),
  "component---src-pages-digital-transformation-consulting-en-js": () => import("./../../../src/pages/digital-transformation-consulting.en.js" /* webpackChunkName: "component---src-pages-digital-transformation-consulting-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-de-js": () => import("./../../../src/pages/legal-notice.de.js" /* webpackChunkName: "component---src-pages-legal-notice-de-js" */),
  "component---src-pages-legal-notice-en-js": () => import("./../../../src/pages/legal-notice.en.js" /* webpackChunkName: "component---src-pages-legal-notice-en-js" */),
  "component---src-pages-our-work-de-js": () => import("./../../../src/pages/our-work.de.js" /* webpackChunkName: "component---src-pages-our-work-de-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-privacy-policy-de-js": () => import("./../../../src/pages/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-privacy-policy-de-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-software-engineering-de-js": () => import("./../../../src/pages/software-engineering.de.js" /* webpackChunkName: "component---src-pages-software-engineering-de-js" */),
  "component---src-pages-software-engineering-en-js": () => import("./../../../src/pages/software-engineering.en.js" /* webpackChunkName: "component---src-pages-software-engineering-en-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

